import React from 'react';

export default function DisplayProgress({ currentProgress, fetchedData }) {
	//if fetchedData is not undefined, create array of steps
	//const steps = fetchedData.steps ? fetchedData.steps.slice(1) : [];

	//if fetchedData is not undefined, and step == category create array of steps
	const steps = fetchedData.steps
		? fetchedData.steps.filter((step) => step.step_type === 'category')
		: [];

	//make a single array of all questions from all steps
	const questions = steps.map((step) => step.questions).flat();

	//get index of currentProgress.currentQuestion
	const currentQuestionIndex = questions.findIndex(
		(question) => question.uuid === currentProgress.currentQuestion
	);
	function currentQuestion() {
		return currentQuestionIndex + 1;
	}

	return (
		<>
			<div className='progress-wrapper'>
				<span>
					{currentQuestion()} / {questions.length}
				</span>
			</div>
		</>
	);
}
