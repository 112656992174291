import React from 'react';

export default function GoodToKnow({ fetchedData }) {
	//get steps where stepe_type == summary from fetchedData
	const SummaryStep = fetchedData.steps
		? fetchedData.steps.filter((step) => step.step_type === 'summary')
		: [];

	return (
		<div>
			<div className='gtk-wrapper'>
				<div className='image-wrapper'>
					<img
						src={SummaryStep[0].good_to_know_icon}
						alt='placeholder'
					/>
				</div>
				<div className='text-wrapper'>
					<h2>{SummaryStep[0].good_to_know_headline}</h2>
					<p>{SummaryStep[0].good_to_know_paragraph}</p>
				</div>
			</div>
		</div>
	);
}
