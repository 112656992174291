import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function ResultPage({ answer, baseURL }) {
	const [result, setResult] = useState();
	const [ListRendering, setListToRender] = useState([]);
	const [freeTextRendering, setFreeTextToRender] = useState();

	const fetchResult = async () => {
		try {
			const res = await axios.get(
				baseURL + `/page/special`,
				{
					params: { system_name: 'pdf', replace_system_names: true },
				}
			);
			setResult(res.data.data);
		} catch (err) {
			setResult('error');
		}
	};

	useEffect(() => {
		// Trigger the API Call
		fetchResult();
	}, []);

	//check if result is undefined
	if (result !== undefined) {
		console.log(result);
	}
	useEffect(() => {
		// for each answer, check if the answer.id exist as text in the result.bullets
		var ListItems = [];
		var FreeTextItems = '';

		//check if result is undefined
		if (result !== undefined) {
			FreeTextItems = result.first_paragraph;
			answer.forEach((item) => {
				const index = result.bullets.findIndex((bullet) =>
					bullet.includes(item.id)
				);

				if (index !== -1) {
					var newValue = result.bullets[index];
					newValue = newValue.replaceAll(item.id, item.value[0].summmary ? item.value[0].summmary : item.value[0].OptionValue);
					ListItems.push(newValue);
				}
				if (result.first_paragraph.includes(item.id)) {
					FreeTextItems = FreeTextItems.replace(
						item.id,
						item.value[0].summmary ? item.value[0].summmary : item.value[0].OptionValue
					);
				}
			});
			setFreeTextToRender(FreeTextItems);
			setListToRender(ListItems);
		}
	}, [result]);

	function formatText(text) {
		if (text !== undefined) {
			const matches = text.match(/{(.*?)}/g);
			if (matches) {
				matches.forEach((match) => {
					const value = match.replace('{', '').replace('}', '');
					const span = `<span>${value}</span>`;
					text = text.replace(match, span);
				});
			}
			return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
		}
	}

	return (
		<div className='resultpage-wrapper'>
			<h1>{result ? result.first_heading : ''}</h1>
			<div className='summary-box summary-history'>
				<h2>{result ? result.first_subheading : ''}</h2>
				{formatText(freeTextRendering)}
			</div>
			<h1>{result ? result.second_heading : ''}</h1>
			<div className='summary-box summary-Symptoms'>
				<h2>{result ? result.second_subheading : ''}</h2>
				<div className='content-wrapper'>
					<div className='list-wrapper'>
						<ul>
							{ListRendering.map((item) => (
								<li key={item}>{formatText(item)}</li>
							))}
						</ul>
					</div>
					<div className='image-wrapper'>
						<img
							src={result ? result.report_image : ''}
							alt='mother-baby'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
