import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import PDFFile from './PDFFile';
import { track } from './../_helpers/postmessage';
import axios from 'axios';

export default function Summary({
	answer,
	baseURL,
	setAnswer,
	setCurrentProgress,
	fetchedData,
	setDisplayGTK,
}) {
	const [showEmail, setShowEmail] = useState(false);
	const [blob64, setBlob64] = useState();
	const [pdfEmail, setPdfEmail] = useState('');
	const [result, setResult] = useState();

	useEffect(() => {
		setDisplayGTK(true);
	}, [setDisplayGTK]);
	useEffect(() => {
		track('event', 'questionnaire_completed');
		fetchResult();
	}, []);

	const fetchResult = async () => {
		try {
			const res = await axios.get(
				baseURL + `/page/special`,

				{
					params: { system_name: 'pdf', replace_system_names: true },
				}
			);
			setResult(res.data.data);
		} catch (err) {
			setResult('error');
		}
	};

	function resetQuiz() {
		setCurrentProgress(() => ({
			currentStep: fetchedData.steps && fetchedData.steps[0].system_name,
			currentCategory: fetchedData.steps && fetchedData.steps[0].uuid,
			currentQuestion:
				fetchedData.steps && fetchedData.steps[1].questions[0].uuid,
			currentQuestionType:
				fetchedData.steps && fetchedData.steps[1].questions[0].response_type,
		}));
		setAnswer([]);
		setDisplayGTK(false);
	}

	function openEmailInput() {
		showEmail && validateEmail(pdfEmail);
		setShowEmail(true);
		document.getElementById('emailInputField').classList.remove('input-hidden');
		document.getElementById('emailButton').innerHTML = summaryStep.email_submit_button_text;
		document
			.getElementsByClassName('download-pdf')[0]
			.classList.add('input-hidden');
	}

	const theBody = document.getElementsByTagName('body')[0];
	useEffect(() => {
		if (showEmail === true) {
			theBody.addEventListener('click', handleEmailToggle, false);
			theBody.addEventListener('touchstart', handleEmailToggle, false);
		}
	}, [showEmail]);

	function handleEmailToggle(e) {
		if (
			showEmail &&
			e.target.id !== 'buttonWrapper' &&
			e.target.id !== 'emailInputField' &&
			e.target.id !== 'emailButton' &&
			e.target.id !== 'emailButton'
		) {
			e.preventDefault();
			setShowEmail(!showEmail);
			document.getElementById('emailInputField').classList.add('input-hidden');
			document.getElementById('emailButton').innerHTML = summaryStep.email_button_text;
			document
				.getElementsByClassName('download-pdf')[0]
				.classList.remove('input-hidden');
			theBody.removeEventListener('click', handleEmailToggle, false);
			theBody.removeEventListener('touchstart', handleEmailToggle, false);
		}
	}

	function validateEmail(email) {
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (re.test(email)) {
			sendPdfToBackend(email);
		} else {
			let elementToHighlight = document.getElementById('emailInputField');
			elementToHighlight.style.borderColor = 'red';
			elementToHighlight.style.boxShadow = 'inset 0px 0px 10px 0px #f00';
			emailStatus(500, summaryStep.email_invalid_text);
			const timeout = setTimeout(() => {
				elementToHighlight.style.borderColor = '';
				elementToHighlight.style.boxShadow = '';
			}, 2100);
			return () => clearTimeout(timeout);
		}
	}

	function emailStatus(code, messageText) {
		let message = document.getElementById('emailMessage');
		message.innerHTML = messageText;
		switch (code) {
			case 200:
				message.classList.add('success');
				break;
			case 500:
			default:
				message.classList.add('error');
				break;
		}
		const timeout = setTimeout(() => {
			message.innerHTML = '';
			message.classList.remove('error');
			message.classList.remove('success');
		}, 2100);
		return () => clearTimeout(timeout);
	}

	const sendPdfToBackend = async (mail) => {
		try {
			document.querySelector('.spinning-loader-wrapper').style.display = "block";
			var requestData = new FormData();
			requestData.append('email', pdfEmail);
			requestData.append('pdfData', blob64);
			const res = await axios.post(
				baseURL + `/email/pdf`,
				requestData,
				{
					headers: {"Content-Type": "multipart/form-data"}
				}
			);
			document.querySelector('.spinning-loader-wrapper').style.display = "none";
			if (res.status === 200) {
				emailStatus(200, summaryStep.email_success_text);
				setShowEmail(!showEmail);
				document.getElementById('emailInputField').classList.add('input-hidden');
				document.getElementById('emailButton').innerHTML = summaryStep.email_button_text;
				document
					.getElementsByClassName('download-pdf')[0]
					.classList.remove('input-hidden');
				theBody.removeEventListener('click', handleEmailToggle, false);
				theBody.removeEventListener('touchstart', handleEmailToggle, false);
			} else if (res.status === 500) {
				emailStatus(500, summaryStep.email_failed_text);
			}
		} catch (err) {
			document.querySelector('.spinning-loader-wrapper').style.display = "none";
			emailStatus(err.request.status, summaryStep.email_failed_text);
		}
	};

	//get step from fetchedData where system_name === 'summary'
	const summaryStep =
		fetchedData.steps &&
		fetchedData.steps.find((step) => step.system_name === 'summary');

	return (
		<div className='summary-wrapper'>
			<div className='summary-content-wrapper'>
				<div className='summary-info'>
					<h1>{summaryStep.main_heading}</h1>
					<div className='summary-info-content'>
						<div>
							<h2 dangerouslySetInnerHTML={{__html:summaryStep.left_heading}}></h2>
							<p dangerouslySetInnerHTML={{__html:summaryStep.first_left_paragraph}}></p>
							<p dangerouslySetInnerHTML={{__html:summaryStep.second_left_paragraph}}></p>
						</div>
						<div>
							<h2 dangerouslySetInnerHTML={{__html:summaryStep.right_heading}}></h2>
							<p dangerouslySetInnerHTML={{__html:summaryStep.right_paragraph}}></p>
							<h3 dangerouslySetInnerHTML={{__html:summaryStep.list_heading}}></h3>
							<ul>
								{summaryStep.bullets.map((item) => (
									<li key={item}>{item}</li>
								))}
							</ul>
						</div>
					</div>
				</div>

				<div className='download-wrapper'>
					<div className='text-button-wrapper'>
						<div className='text-wrapper'>
							<h2 dangerouslySetInnerHTML={{__html:summaryStep.actions_heading}}></h2>
							<p dangerouslySetInnerHTML={{__html:summaryStep.actions_paragraph}}></p>
						</div>
						<div
							id='buttonWrapper'
							className='button-wrapper result-button-wrapper'
						>
							{blob64 ? (
								<>
									<input
										id='emailInputField'
										type='email'
										name='pdf-email'
										className='email-input input-hidden'
										onChange={(e) => setPdfEmail(e.target.value)}
										placeholder={summaryStep.email_placeholder_text}
									/>
									<button
										id='emailButton'
										className='email-button navigation-button forward-button'
										onClick={openEmailInput}
									>
										{summaryStep.email_button_text}
									</button>
									<div className="spinning-loader-wrapper">
										<div className="spinning-loader">

										</div>
									</div>
								</>
							) : (
								<button className='navigation-button back-button'>...</button>
							)}
							<PDFDownloadLink
								document={<PDFFile answer={answer} baseURL={baseURL} />}
								fileName={result ? result.report_heading : 'PDF'}
								className='navigation-button forward-button download-pdf'
							>
								{({ loading, url, blob }) => {
									let base64String;
									let reader = new FileReader();
									blob && reader.readAsDataURL(blob);
									reader.onloadend = () => {
										base64String = reader.result;
										setBlob64(base64String);
									};

									return loading ? (
										<button className='navigation-button forward-button'>...</button>
									) : (
										<button className='navigation-button forward-button'>
											{summaryStep.download_button_text}
										</button>
									);
								}}
							</PDFDownloadLink>
						</div>
						<div className='email-message-wrapper'>
							<div className='email-message' id='emailMessage'>

							</div>
						</div>
					</div>
					<div className='image-wrapper'>
						<img
							src={summaryStep ? summaryStep.actions_image : ''}
							alt='placeholder'
						></img>
					</div>
				</div>
				<div className='reset-button-wrapper'>
					<button
						className='navigation-button back-button'
						onClick={resetQuiz}
					>
						{summaryStep.restart_button_text}
					</button>
				</div>
			</div>
		</div>
	);
}
