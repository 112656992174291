import React from 'react';
import QuestionSingeChoice from './Questions/QustionSingleChoice';
import QuestionMultipleChoice from './Questions/QuestionMultipleChoice';
import QuestionNumber from './Questions/QuestionNumber';
import QuestionText from './Questions/QuestionText';
export default function Questions({
	currentProgress,
	fetchedData,
	answer,
	updateAnswer,
}) {
	//check if fetchedData is empty, if not return question steps === currentProgress.currentCategory
	const currentQuestion =
		fetchedData &&
		fetchedData.steps &&
		fetchedData.steps
			.filter((step) => step.uuid === currentProgress.currentCategory)
			.map((step) => step.questions)
			.flat()
			.filter((question) => question.uuid === currentProgress.currentQuestion);

	function checkCurrentQuestion() {
		if (currentProgress.currentQuestionType === 'multiple-choice') {
			return (
				<QuestionMultipleChoice
					currentQuestion={currentQuestion}
					answer={answer}
					updateAnswer={updateAnswer}
				/>
			);
		} else if (currentProgress.currentQuestionType === 'single-choice') {
			return (
				<QuestionSingeChoice
					currentQuestion={currentQuestion}
					answer={answer}
					updateAnswer={updateAnswer}
				/>
			);
		} else if (currentProgress.currentQuestionType === 'number') {
			return (
				<QuestionNumber
					currentQuestion={currentQuestion}
					answer={answer}
					updateAnswer={updateAnswer}
				/>
			);
		} else if (currentProgress.currentQuestionType === 'textbox') {
			return (
				<QuestionText
					currentQuestion={currentQuestion}
					answer={answer}
					updateAnswer={updateAnswer}
				/>
			);
		}
	}

	return (
		<div className='questionHolder-wrapper'>
			<div className='questionHolder-content-wrapper'>
				{checkCurrentQuestion()}
			</div>
		</div>
	);
}
