import React from 'react';

export default function SkipButton({
	currentProgress,
	setCurrentProgress,
	fetchedData,
	updateAnswer,
}) {
	const steps = fetchedData.steps && fetchedData.steps;

	const listedQuestions =
		steps &&
		steps
			.filter((step) => step.uuid === currentProgress.currentCategory)
			.map((step) => step.questions);

	//check if steps contains questions
	const existingQuestions =
		listedQuestions && listedQuestions.length > 0 && listedQuestions[0];

	//get index of currentProgress.currentQuestion
	const currentQuestionIndex =
		existingQuestions &&
		existingQuestions.findIndex(
			(question) => question.uuid === currentProgress.currentQuestion
		);

	//get index of currentProgress.currentStep
	const currentCategoryIndex =
		steps &&
		steps.findIndex((step) => step.system_name === currentProgress.currentStep);

	function handleClick() {
		const id = currentProgress.currentQuestion;
		let ValueObject = {
			OptionValue: '-',
			summary: '-',
		};
		updateAnswer(id, [ValueObject]);

		if (currentQuestionIndex === existingQuestions.length - 1) {
			const nextQuestion =
				steps[currentCategoryIndex + 1].questions[0] === undefined ||
				steps[currentCategoryIndex + 1].questions[0] === ''
					? 'no questions'
					: steps[currentCategoryIndex + 1].questions[0].uuid;

			const nextQuestionType =
				steps[currentCategoryIndex + 1].questions[0] === undefined ||
				steps[currentCategoryIndex + 1].questions[0] === ''
					? 'no questions'
					: steps[currentCategoryIndex + 1].questions[0].response_type;

			setCurrentProgress((existingValues) => ({
				...existingValues,
				currentStep: steps[currentCategoryIndex + 1].system_name,
				currentCategory: steps[currentCategoryIndex + 1].uuid,
				currentQuestion: nextQuestion,
				currentQuestionType: nextQuestionType,
			}));
		} else {
			setCurrentProgress((existingValues) => ({
				...existingValues,
				currentQuestion: existingQuestions[currentQuestionIndex + 1].uuid,
				currentQuestionType:
					existingQuestions[currentQuestionIndex + 1].response_type,
			}));
		}
	}
	return (
		<span onClick={handleClick}>{fetchedData.theme !== undefined ? fetchedData.theme.skip_button_text : ''}</span>
	);
}
