import React from 'react';
import Progressbar from './Progressbar';

function CategoryStatus({ category, currentProgress, fetchedData }) {
	const activeCategory =
		currentProgress.currentCategory === category.uuid ? true : false;
	//if category.uuid is placed before currentProgress.currentCategory in fetchtedData.steps, then set finshedCategory to true
	if (fetchedData.steps) {
		const categories2 = fetchedData.steps;
		const CategoryList = categories2.map((category) => {
			return category.uuid;
		});
		var finishedCategory =
			CategoryList.indexOf(currentProgress.currentCategory) >
			CategoryList.indexOf(category.uuid)
				? true
				: false;
	}

	return (
		<div
			className={
				activeCategory || finishedCategory
					? 'status-wrapper status-active'
					: 'status-wrapper'
			}
			data-id={category.uuid}
		>
			<div
				className={
					finishedCategory
						? 'category-complete image-text-holder'
						: 'image-text-holder'
				}
			>
				<img
					src={finishedCategory ? category.completed_icon : category.icon}
					alt='placeholder'
				/>
				<span>{category.display_name}</span>
			</div>
			<Progressbar
				fetchedData={fetchedData}
				currentProgress={currentProgress}
				category={category}
				activeCategory={activeCategory}
				finishedCategory={finishedCategory}
			/>
		</div>
	);
}

export default CategoryStatus;
