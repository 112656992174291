


export default function resize() {
    
    let message = {
        messageType: 'resize',
        height: document.body.scrollHeight,
    };
   
    window.top.postMessage(message, '*');
}

export function track(type, name) {
    let message = { 
        messageType: 'track',
        type: type, 
        name: name
    }
   
   
     window.top.postMessage(message, '*') 
}

