import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import QuestionHolder from './QuestionHolder';
import StartPage from './StartPage';
import Summary from './Summary';
import Footer from './Footer';
import ResultPage from './ResultPage';
import resize from './../_helpers/postmessage';
import PDFFile from './PDFFile';
import { PDFViewer } from '@react-pdf/renderer';

export default function MainModule({
	fetchedData,
	fetchDataStatus,
	baseURL,
	setDisplayDisclaimer,
	setDisplayGTK,
}) {
	const [currentProgress, setCurrentProgress] = useState({
		currentStep: null,
		currentCategory: null,
		currentQuestion: null,
	});

	const [answer, setAnswer] = useState([]);
	const [displayResult, setDisplayResult] = useState(false);

	function updateAnswer(id, value) {
		//check if answer is empty

		const index = answer.findIndex((item) => item.id === id);

		if (index !== -1) {
			const newAnswers = answer.map((c, i) => {
				if (i === index) {
					// Update index of current answers
					return { id, value };
				} else {
					// The rest stays the same
					return c;
				}
			});
			setAnswer(newAnswers);
		} else {
			setAnswer([...answer, { id, value }]);
		}
	}

	useEffect(() => {
		resize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProgress]);

	const firstRender = useRef(true);
	useEffect(() => {
		//Trigger the API Call
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setCurrentProgress(() => ({
				currentStep: fetchedData.steps && fetchedData.steps[0].system_name,
				currentCategory: fetchedData.steps && fetchedData.steps[0].uuid,
				currentQuestion:
					fetchedData.steps && fetchedData.steps[1].questions[0].uuid,
				currentQuestionType:
					fetchedData.steps && fetchedData.steps[1].questions[0].response_type,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedData]);

	const rendeContent = () => {
		if (displayResult) {
			return (
				<ResultPage
					answer={answer}
					baseURL={baseURL}
				/>
			);
		} else if (currentProgress.currentStep === 'startpage') {
			return (
				<StartPage
					fetchedData={fetchedData}
					currentProgress={currentProgress}
					setCurrentProgress={setCurrentProgress}
					setDisplayDisclaimer={setDisplayDisclaimer}
					baseURL={baseURL}
				/>
			);
		} else if (currentProgress.currentStep === 'summary') {
			return (
				<>
					<Header
						currentProgress={currentProgress}
						fetchedData={fetchedData}
					/>
					<Summary
						answer={answer}
						currentProgress={currentProgress}
						fetchedData={fetchedData}
						setDisplayResult={setDisplayResult}
						setCurrentProgress={setCurrentProgress}
						setAnswer={setAnswer}
						setDisplayGTK={setDisplayGTK}
						baseURL={baseURL}
					/>
				</>
			);
		} else if (fetchDataStatus === 'error') {
			return <div>Something went wrong</div>;
		} else {
			return (
				<>
					<Header
						currentProgress={currentProgress}
						fetchedData={fetchedData}
					/>
					<QuestionHolder
						currentProgress={currentProgress}
						fetchedData={fetchedData}
						answer={answer}
						updateAnswer={updateAnswer}
					/>
					<Footer
						fetchedData={fetchedData}
						currentProgress={currentProgress}
						setCurrentProgress={setCurrentProgress}
						firstRender={firstRender.current}
						answer={answer}
						updateAnswer={updateAnswer}
						setAnswer={setAnswer}
					/>
				</>
			);
		}
	};

	return <div className='main-frame'>{rendeContent()}</div>;
}
