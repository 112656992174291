import React, { useState, useEffect } from 'react';

export default function QuestionNumber({
	currentQuestion,
	updateAnswer,
	answer,
}) {
	const [value, setValue] = useState({ OptionValue: '' });

	//check if answer contains currentQuestion[0].uuid
	useEffect(() => {
		let result = {
			OptionValue: '',
		};
		const index = answer.findIndex(
			(item) => item.id === currentQuestion[0].uuid
		);
		if (index !== -1) {
			result = {
				OptionValue: answer[index].value[0].OptionValue,
			};
		}
		setValue(result);
	}, [answer, currentQuestion]);

	const handleChange = (event) => {
		const textValue = event.target.value.replace(/\D/g, '');

		let result = {
			OptionValue: textValue,
		};
		setValue(result);
		updateAnswer(currentQuestion[0].uuid, [result]);
	};

	return (
		<div className='Question-number-wrapper'>
			<div>
				<h1>{currentQuestion[0].formulation}</h1>
				<p>{currentQuestion[0].explanation}</p>
			</div>
			<div className='input-wrapper'>
				<input
					data-id={currentQuestion[0].uuid}
					type='text'
					value={value.OptionValue}
					onChange={handleChange}
				/>
				<span>{currentQuestion[0].response_unit ?? ''}</span>
			</div>
		</div>
	);
}
