import React, { useState, useEffect } from 'react';
import {
	Page,
	View,
	Text,
	Image,
	Document,
	StyleSheet,
	Svg,
	Path,
	Font,
} from '@react-pdf/renderer';
import axios from 'axios';
import bariolRegular from '../Assets/Fonts/Bariol-Regular.ttf';
import bariolLight from '../Assets/Fonts/Bariol-Light.ttf';
import bariolBold from '../Assets/Fonts/Bariol-Bold.ttf';
import nutriciaIcon from '../Assets/Images/Icons/Nutricia-logo-no-strapline-rgb-grad.png';
import { ThemeVariables } from '../App';

Font.register({
	family: 'bariol',
	fonts: [
		{
			src: bariolRegular,
			fontWeight: 400,
		},
		{
			src: bariolLight,
			fontWeight: 300,
		},
		{ src: bariolBold, fontWeight: 700 },
	],
});

const styles = StyleSheet.create({
	mainFrame: {
		overflow: 'hidden',
		padding: 0,
		height: '90%',
		width: '96%',
		margin: '2%',
		marginTop: 0,
	},
	resultpageWrapper: {
		height: '92%',
		width: '100%',
		boxSizing: 'border-box',
		padding: '4% 6%',
	},
	summaryBox: {
		borderRadius: '4px',
		padding: '2% 3%',
		width: '100%',
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflow: 'visible',
	},
	pWrapper: {
		paddingBottom: '6px',
	},
	listWrapper: {
		maxWidth: '64%',
	},
	hOne: {
		fontSize: '26px',
		marginBottom: '4%',
		fontFamily: 'bariol',
		fontWeight: 400,
	},
	hTwo: {
		fontSize: '20px',
		marginBottom: '2%',
		fontFamily: 'bariol',
		fontWeight: 400,
	},
	p: {
		fontSize: '14px',
		lineHeight: 1.4,
		marginBottom: '1%',
		fontFamily: 'bariol',
		fontWeight: 400,
	},
	span: {
		fontFamily: 'bariol',
		fontWeight: 700,
	},
	img: {
		objectFit: 'contain',
		margin: 0,
		padding: 0,
		height: '110%',
		maxWidth: '32%',
		position: 'absolute',
		bottom: '-200px',
		right: '2%',
	},
	svgContainer: {
		position: 'absolute',
		right: '-420px',
		bottom: '-200px',
	},
	svg: { transform: 'scale(-1, 1) translate(0, -100)' },
	path: {
		opacity: 0.5,
	},
	logo: {
		objectFit: 'contain',
		height: '8%',
		width: '19%',
		marginLeft: '3%',
	},
	reportHeading: {
		position: 'absolute',
		right: 0,
		marginRight: '3%',
		marginTop: '4.8%',
		height: '8%',
	},
});

const PDFFile = ({ answer, baseURL }) => {
	const [result, setResult] = useState();
	const [ListRendering, setListToRender] = useState([]);
	const [freeTextRendering, setFreeTextToRender] = useState([]);

	const fetchResult = async () => {
		try {
			const res = await axios.get(
				baseURL + `/page/special`,
				{
					params: { system_name: 'pdf', replace_system_names: true },
				}
			);
			setResult(res.data.data);
		} catch (err) {
			setResult('error');
		}
	};

	useEffect(() => {
		// Trigger the API Call
		fetchResult();
	}, []);

	//check if result is undefined
	if (result !== undefined) {
	}
	useEffect(() => {
		// for each answer, check if the answer.id exist as text in the result.bullets
		var ListItems = [];
		var FreeTextItems = '';
		let returnFreeText = [];

		//check if result is undefined
		if (result !== undefined) {
			FreeTextItems = result.first_paragraph;

			answer.forEach((item) => {
				const index = result.bullets.findIndex((bullet) =>
					bullet.includes(item.id)
				);

				if (index !== -1) {
					var newValue = result.bullets[index];
					newValue = item.value[0].summary
						? newValue.replaceAll(item.id, item.value[0].summary)
						: newValue.replaceAll(item.id, item.value[0].OptionValue);
					ListItems.push(newValue);
				}
				if (result.first_paragraph.includes(item.id)) {
					if (item.value.length > 1) {
						var tmpStr = "";
						item.value.forEach((ansEl, idx) => {
						  if (idx == 0) {
							tmpStr = ansEl.summary ? ansEl.summary : ansEl.OptionValue;
						  } else {
							tmpStr += ansEl.summary
							  ? ", " + ansEl.summary
							  : ", " + ansEl.OptionValue;
						  }
						});
					  } else {
						var tmpStr = item.value[0].summary
						  ? item.value[0].summary
						  : item.value[0].OptionValue;
					  }
					  FreeTextItems = item.value[0].summary
						? FreeTextItems.replace(item.id, tmpStr)
						: FreeTextItems.replace(item.id, tmpStr);
				}
				if (FreeTextItems.match(/[{}]/g)) {
					FreeTextItems = FreeTextItems.replace('{', '').replace('}', '');
				}
			});
			const matches = FreeTextItems.match(/(<\/b>)/g);
			if (matches) {
				matches.forEach(() => {
					returnFreeText.push(
						FreeTextItems.substring(0, FreeTextItems.indexOf('<b>'))
					);
					returnFreeText.push(
						FreeTextItems.substring(
							FreeTextItems.indexOf('<b>') + 3,
							FreeTextItems.indexOf('</b>')
						)
					);
					FreeTextItems = FreeTextItems.substring(
						FreeTextItems.indexOf('</b>') + 4
					);
				});
				returnFreeText.push(FreeTextItems);
			}
			setFreeTextToRender(returnFreeText);
			setListToRender(ListItems);
		}
	}, [result]);

	function formatText(text) {
		if (text !== undefined) {
			let returnText = text;
			let preAnswer = '';
			let boldAnswer = '';
			let postAnswer = '';
			const matches = returnText.match(/{(.*?)}/g);
			if (matches) {
				matches.forEach((match) => {
					// Remove the variable delimiters {} (variables already replaced)
					returnText = returnText.replace(
						match,
						match.replace('{', '').replace('}', '')
					);
					// Put the bold part (only one allowed currently) in a bolded element
					if (returnText.match(/(<\/b>)/g)) {
						preAnswer = returnText.substring(0, returnText.indexOf('<b>'));
						boldAnswer = returnText.substring(
							returnText.indexOf('<b>') + 3,
							returnText.lastIndexOf('</b>')
						);
						postAnswer = returnText.substring(returnText.lastIndexOf('</b>')+4)
					} else {
						preAnswer = returnText;
						boldAnswer = '';
						postAnswer = '';
					}
				});
			}
			return (
				<Text style={styles.p}>
					{preAnswer}
					<Text style={styles.span}>{boldAnswer}</Text>
					{postAnswer}
				</Text>
			);
		}
	}

	function renderFreeFunction(e, index) {
		return index % 2 === 0 ? (
			<Text key={e + index}>{e}</Text>
		) : (
			<Text
				key={e + index}
				style={styles.span}
			>
				{e}
			</Text>
		);
	}

	return (
		<Document>
			<Page>
				<Image
					src={(result && result.report_logo) ?? 'Icons/Nutricia-logo-no-strapline-rgb-grad.png'}
					style={styles.logo}
				/>
				<Text
					style={[
						styles.hTwo,
						styles.reportHeading,
						{
							color: ThemeVariables.primary_front_color
								? ThemeVariables.primary_front_color
								: '#2B3C90',
						},
					]}
				>
					{result && result.report_heading}
				</Text>
				<View
					style={[
						styles.mainFrame,
						{
							backgroundColor: ThemeVariables.primary_background_color
								? ThemeVariables.primary_background_color
								: '#e9e1f1',
						},
					]}
				>
					<View style={styles.svgContainer}>
						<Svg
							width='1962'
							height='774'
							style={styles.svg}
						>
							<Path
								d='M 1923.46 774 l -1803.1 -0.42 s -61.58 -51.591 -96.644 -130.862 c -3.75 -8.456 -7.188 -17.229 -10.217 -26.293 c -1.9 -5.671 -3.642 -11.45 -5.185 -17.342 c -0.321 -1.194 -0.626 -2.393 -0.925 -3.6 A 241.025 241.025 0 0 1 0.471 521.7 a 229.857 229.857 0 0 1 2.614 -22.855 q 1.661 -9.981 4.267 -20.144 c 0.313 -1.205 0.634 -2.4 0.969 -3.608 A 258.08 258.08 0 0 1 31.755 418.25 q 4.326 -7.808 9.329 -15.673 a 359.082 359.082 0 0 1 38.5 -49.773 a 585.644 585.644 0 0 1 49.134 -47.881 c 0.314 -0.272 0.626 -0.545 0.939 -0.812 q 11.077 -9.521 22.24 -18.087 c 0.088 -0.068 0.175 -0.136 0.262 -0.2 C 311.727 163.436 484.946 182.5 616.721 250.747 c 15.6 8.075 41.262 21.752 73.421 35.078 c 12.6 5.227 26.2 10.4 40.592 15.161 q 4.828 1.594 9.758 3.125 q 6.739 2.09 13.677 4.029 q 2.85 0.792 5.73 1.557 c 10.837 2.881 21.994 5.444 33.39 7.552 q 6.958 1.287 14.033 2.347 a 358.708 358.708 0 0 0 43.812 3.863 q 11.655 0.281 23.442 -0.289 c 69.671 -3.49 141.708 -31.345 201.687 -107.553 c 270.415 -343.567 919.015 -264.189 782.026 150.153 c -66.3 200.511 201.758 207.8 65.176 408.228'
								style={[
									styles.path,
									{
										fill: ThemeVariables.secondary_background_color
											? ThemeVariables.secondary_background_color
											: '#faf6ff',
									},
								]}
							/>
						</Svg>
					</View>
					<View style={styles.resultpageWrapper}>
						<Text
							style={[
								styles.hOne,
								{
									color: ThemeVariables.primary_front_color
										? ThemeVariables.primary_front_color
										: '#2B3C90',
								},
							]}
						>
							{result ? result.first_heading : ''}
						</Text>
						<View
							style={[
								styles.summaryBox,
								{
									marginBottom: '10%',
									backgroundColor: ThemeVariables.secondary_background_color
										? ThemeVariables.secondary_background_color
										: '#faf6ff',
									color: ThemeVariables.primary_front_color
										? ThemeVariables.primary_front_color
										: '#2B3C90',
								},
							]}
						>
							<Text
								style={[
									styles.hTwo,
									{
										color: ThemeVariables.primary_front_color
											? ThemeVariables.primary_front_color
											: '#2B3C90',
									},
								]}
							>
								{result ? result.first_subheading : ''}
							</Text>
							<Text style={[styles.p, { paddingBottom: '4px' }]}>
								{freeTextRendering.map((item, index) => {
									return renderFreeFunction(item, index);
								})}
							</Text>
						</View>
						<Text
							style={[
								styles.hOne,
								{
									color: ThemeVariables.primary_front_color
										? ThemeVariables.primary_front_color
										: '#2B3C90',
								},
							]}
						>
							{result ? result.second_heading : ''}
						</Text>
						<View
							style={[
								styles.summaryBox,
								{
									backgroundColor: ThemeVariables.secondary_background_color
										? ThemeVariables.secondary_background_color
										: '#faf6ff',
									color: ThemeVariables.primary_front_color
										? ThemeVariables.primary_front_color
										: '#2B3C90',
								},
							]}
						>
							<View style={styles.contentWrapper}>
								<Text
									style={[
										styles.listWrapper,
										styles.hTwo,
										{
											color: ThemeVariables.primary_front_color
												? ThemeVariables.primary_front_color
												: '#2B3C90',
										},
									]}
								>
									{result ? result.second_subheading : ''}
								</Text>
								<View style={[styles.pWrapper, styles.listWrapper]}>
									{ListRendering.map((item) => (
										<View
											key={item}
											style={{
												display: 'flex',
												flexDirection: 'row',
												position: 'relative',
												overflow: 'visible',
											}}
										>
											<Text style={[styles.p, { paddingRight: '6px' }]}>•</Text>
											{formatText(item)}
										</View>
									))}
								</View>
							</View>
						</View>
					</View>
					<Image
						src={(result && result.report_image) ?? 'mother-baby.png'}
						style={styles.img}
					/>
				</View>
			</Page>
		</Document>
	);
};

export default PDFFile;
