import React from 'react';

export default function Progressbar({
	category,
	currentProgress,
	activeCategory,
	finishedCategory,
}) {
	const numberOfQuestions = category.questions.length;

	const questionsList = category.questions.map((question) => {
		return question.uuid;
	});

	let progressPercent =
		(questionsList.indexOf(currentProgress.currentQuestion) /
			numberOfQuestions) *
		100;

	if (numberOfQuestions === 0) {
		progressPercent = 100;
	}
	return (
		<div className='progress-bar'>
			<div
				className={finishedCategory ? 'progress progress-finished' : 'progress'}
				style={activeCategory ? { width: `${progressPercent}%` } : {}}
			></div>
		</div>
	);
}
