import React, { useEffect, useState } from 'react';

export default function NavButton({
	fetchedData,
	currentProgress,
	isNext,
	setCurrentProgress,
	answer,
	setAnswer,
}) {
	// if fetchedData exist, make array of all steps and include questions
	const [isAnswered, setAnswered] = useState(false);

	useEffect(() => {
		if (isNext) {
			let optionValueIsEmptyString = true;
			//check if currentProgress.currentQuestion matches any list item from answer.uuid
			const index = answer.findIndex((item) => {
				if (
					item?.value[0]?.OptionValue === '' ||
					item?.value[0]?.value === '' ||
					(item.id === currentProgress.currentQuestion &&
						item.value.length <= 0)
				) {
					optionValueIsEmptyString = false;
				}
				return item.id === currentProgress.currentQuestion;
			});
			if (index !== -1 && optionValueIsEmptyString) {
				setAnswered(true);
			} else {
				setAnswered(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answer, currentProgress.currentQuestion]);

	const steps = fetchedData.steps && fetchedData.steps;

	const listedQuestions =
		steps &&
		steps
			.filter((step) => step.uuid === currentProgress.currentCategory)
			.map((step) => step.questions);

	//check if steps contains questions
	const existingQuestions =
		listedQuestions && listedQuestions.length > 0 && listedQuestions[0];

	//get index of currentProgress.currentQuestion
	const currentQuestionIndex =
		existingQuestions &&
		existingQuestions.findIndex(
			(question) => question.uuid === currentProgress.currentQuestion
		);

	//get index of currentProgress.currentStep
	const currentCategoryIndex =
		steps &&
		steps.findIndex((step) => step.system_name === currentProgress.currentStep);

	function handleClick() {
		if (isNext) {
			if (currentQuestionIndex === existingQuestions.length - 1) {
				const nextQuestion =
					steps[currentCategoryIndex + 1].questions[0] === undefined ||
					steps[currentCategoryIndex + 1].questions[0] === ''
						? 'no questions'
						: steps[currentCategoryIndex + 1].questions[0].uuid;

				const nextQuestionType =
					steps[currentCategoryIndex + 1].questions[0] === undefined ||
					steps[currentCategoryIndex + 1].questions[0] === ''
						? 'no questions'
						: steps[currentCategoryIndex + 1].questions[0].response_type;

				setCurrentProgress((existingValues) => ({
					...existingValues,
					currentStep: steps[currentCategoryIndex + 1].system_name,
					currentCategory: steps[currentCategoryIndex + 1].uuid,
					currentQuestion: nextQuestion,
					currentQuestionType: nextQuestionType,
				}));
			} else {
				setCurrentProgress((existingValues) => ({
					...existingValues,
					currentQuestion: existingQuestions[currentQuestionIndex + 1].uuid,
					currentQuestionType:
						existingQuestions[currentQuestionIndex + 1].response_type,
				}));
			}
		} else {
			if (
				currentQuestionIndex === 0 &&
				steps[currentCategoryIndex - 1].system_name === 'startpage'
			) {
				setCurrentProgress(() => ({
					currentStep: fetchedData.steps && fetchedData.steps[0].system_name,
					currentCategory: fetchedData.steps && fetchedData.steps[1].uuid,
					currentQuestion:
						fetchedData.steps && fetchedData.steps[1].questions[0].uuid,
					currentQuestionType:
						fetchedData.steps &&
						fetchedData.steps[1].questions[0].response_type,
				}));
				setAnswer([]);
			} else if (currentQuestionIndex === 0) {
				setCurrentProgress((existingValues) => ({
					...existingValues,
					currentStep: steps[currentCategoryIndex - 1].system_name,
					currentCategory: steps[currentCategoryIndex - 1].uuid,
					currentQuestion:
						steps[currentCategoryIndex - 1].questions[
							steps[currentCategoryIndex - 1].questions.length - 1
						].uuid,
					currentQuestionType:
						steps[currentCategoryIndex - 1].questions[
							steps[currentCategoryIndex - 1].questions.length - 1
						].response_type,
				}));
			} else {
				setCurrentProgress((existingValues) => ({
					...existingValues,
					currentQuestion: existingQuestions[currentQuestionIndex - 1].uuid,
					currentQuestionType:
						existingQuestions[currentQuestionIndex - 1].response_type,
				}));
			}
		}
	}

	return (
		<button
			className={`
				${isNext ? 'navigation-button forward-button' : 'navigation-button back-button'}
				${!isAnswered && isNext ? 'disabled' : ''}  	
			`}
			onClick={() => handleClick()}
		>
			{isNext
				? fetchedData.theme !== undefined
					? fetchedData.theme.next_button_text
					: ''
				: fetchedData.theme !== undefined
				? fetchedData.theme.back_button_text
				: ''}
		</button>
	);
}
