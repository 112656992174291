import React from 'react';
import DisplayProgress from '../Components/FooterComponents/DisplayProgress';
import NavButton from '../Components/FooterComponents/NavButton';
import SkipButton from '../Components/FooterComponents/SkipButton';

export default function Footer({
	currentProgress,
	setCurrentProgress,
	fetchedData,
	answer,
	updateAnswer,
	setAnswer,
}) {
	return (
		<div className='footer-wrapper'>
			<div className='footer-content-wrapper'>
				<NavButton
					currentProgress={currentProgress}
					setCurrentProgress={setCurrentProgress}
					fetchedData={fetchedData}
					setAnswer={setAnswer}
				/>

				<DisplayProgress
					currentProgress={currentProgress}
					fetchedData={fetchedData}
				/>
				<div className='footer-divider'>
					<SkipButton
						currentProgress={currentProgress}
						setCurrentProgress={setCurrentProgress}
						fetchedData={fetchedData}
						updateAnswer={updateAnswer}
					/>
					<NavButton
						currentProgress={currentProgress}
						isNext
						setCurrentProgress={setCurrentProgress}
						fetchedData={fetchedData}
						answer={answer}
						setAnswer={setAnswer}
					/>
				</div>
			</div>
		</div>
	);
}
