import React, { useState, useEffect } from 'react';
import TextField from '../../Components/QuestionComponents/TextField';
export default function QustionSingleChoice({
	currentQuestion,
	answer,
	updateAnswer,
}) {
	const [selectedValues, setSelectedValues] = useState([]);
	const [followUpIsActive, setFollowUpIsActive] = useState(false);
	const [ThreeItemLayout, setThreeItemLayout] = useState(false);

	useEffect(() => {
		if (alternatives.length === 3) {
			setThreeItemLayout(true);
		} else {
			setThreeItemLayout(false);
		}
		setSelectedValues([]);
		setFollowUpIsActive(false);
		const index = answer.findIndex(
			(item) => item.id === currentQuestion[0].uuid
		);
		if (index !== -1) {
			//setSelectedValues(answer[index].value);
			// check if answer contains answers with followup question
			if (answer[index].value.length > 0) {
				if (answer[index].value[0].extraQuestion === 'true') {
					setFollowUpIsActive(true);
				}
			} else {
				setFollowUpIsActive(false);
			}
			setSelectedValues(answer[index].value);
		}
		// Run! Like go get some data from an API.
	}, [answer, currentQuestion]);
	//

	const handleCheck = (e) => {
		const id = currentQuestion[0].uuid;
		const optionId = e.target.id;
		const value = e.target.value;
		let ValueObject = {
			id: optionId,
			OptionValue: value,
			extraQuestion: e.target.getAttribute('data-tag'),
			summary: e.target.getAttribute('data-summary'),
		};
		if (e.target.getAttribute('data-tag') === 'true' && e.target.checked) {
			ValueObject = {
				id: optionId,
				OptionValue: '',
				extraQuestion: e.target.getAttribute('data-tag'),
				summary: '',
			};
			setFollowUpIsActive(true);
		} else {
			setFollowUpIsActive(false);
		}
		// setValue(value);
		setSelectedValues([ValueObject]);
		updateAnswer(id, [ValueObject]);
	};

	//list all alternarives in currentQuestion
	const alternatives = currentQuestion[0].alternatives;

	// for every item in alternatives, create a button with the text of the alternative
	const alternativesButtons = alternatives.map((alternative) => {
		let hasFollowUpQuestion = false;
		if (alternative.followup_question_uuid !== null) {
			hasFollowUpQuestion = true;
		}

		return (
			<div
				className='form-check'
				key={alternative.uuid}
			>
				<label>
					<input
						data-tag={hasFollowUpQuestion}
						key={alternative.uuid}
						data-summary={alternative.summary_formulation}
						id={alternative.uuid}
						type='radio'
						name='react-tips'
						value={alternative.formulation}
						onChange={handleCheck}
						className='form-check-input'
						checked={
							selectedValues.some &&
							selectedValues.some((item) => item.id === alternative.uuid)
						}
					/>
					<span>{alternative.formulation}</span>
				</label>
			</div>
		);
	});
	return (
		<div
			className={
				ThreeItemLayout
					? 'three-items Question-single-choice-wrapper'
					: 'Question-single-choice-wrapper'
			}
		>
			<div className='question-header-wrapper'>
				<h1>{currentQuestion[0].formulation}</h1>
				<p>{currentQuestion[0].explanation}</p>
			</div>
			<div className='center-wrapper'>
				<div className='input-wrapper'>{alternativesButtons}</div>
			</div>
			<TextField
				followUpIsActive={followUpIsActive}
				answer={answer}
				updateAnswer={updateAnswer}
				currentQuestion={currentQuestion}
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
			/>
		</div>
	);
}
