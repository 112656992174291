import React from 'react';

export default function Disclaimer({ fetchedData }) {
	return (
		<div className='outer-disclaimer-wrapper'>
			<p
				dangerouslySetInnerHTML={{
					__html: fetchedData.steps && fetchedData.steps[0].note_paragraph,
				}}
			></p>
			<p
				dangerouslySetInnerHTML={{
					__html:
						fetchedData.steps && fetchedData.steps[0].disclaimer_paragraph,
				}}
			></p>
		</div>
	);
}
