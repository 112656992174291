import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainModule from './Modules/MainModule';
import Disclaimer from './Modules/Disclaimer';
import GoodToKnow from './Modules/GoodToKnow';
import './scss/styles.scss';
import resize from './_helpers/postmessage';
export let ThemeVariables;

function App() {
	window.addEventListener('resize', function () {
		resize();
	});
	// Allow the parent to trigger the resize function
	window.addEventListener('message', function(e) {
		if (e.data === 'resize') {
			resize();
		}
	});

	const [fetchedData, setFetchedData] = useState({});
	const [fetchDataStatus, setDataStatus] = useState('');
	const [displayDisclaimer, setDisplayDisclaimer] = useState(true);
	const [displayGTK, setDisplayGTK] = useState(false);

	function getParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	function setScssThemeVariables(themeData) {
		document.body.style.setProperty(
			'--primary-background-color',
			themeData.primary_background_color
		);
		document.body.style.setProperty(
			'--secondary-background-color',
			themeData.secondary_background_color
		);
		document.body.style.setProperty(
			'--primary-front-color',
			themeData.primary_front_color
		);
		document.body.style.setProperty(
			'--faded-background-color',
			themeData.faded_background_color
		);
		document.body.style.setProperty(
			'--faded-line-color',
			themeData.faded_line_color
		);
		document.body.style.setProperty(
			'--primary-button-color',
			themeData.primary_button_color
		);
		document.body.style.setProperty(
			'--primary-light-color',
			themeData.primary_light_color
		);
		document.body.style.setProperty(
			'--primary-text-color',
			themeData.primary_text_color
		);
	}

	var country = getParameterByName('market');
	country = country ? "/" + country : '';
	var baseURL = process.env.REACT_APP_API_URL + country;
	
	var quizName = getParameterByName('quiz');
	quizName = quizName ? quizName : 'default_quiz';

	const fetchQuiz = async () => {
		try {
			const res = await axios.get(
				baseURL + `/quiz`,

				{
					headers: {},
					params: { system_name: quizName },
				}
			);

			setFetchedData(res.data.data);
			if (res.data.data.theme !== undefined) {
				ThemeVariables = res.data.data.theme;
				setScssThemeVariables(res.data.data.theme);
			}
		} catch (err) {
			setDataStatus('error');
		}
	};

	useEffect(() => {
		// Trigger the API Call
		fetchQuiz();
	}, []);

	return (
		<div className='App'>
			<MainModule
				fetchedData={fetchedData}
				fetchDataStatus={fetchDataStatus}
				baseURL={baseURL}
				setDisplayDisclaimer={setDisplayDisclaimer}
				setDisplayGTK={setDisplayGTK}
				// currentProgress={currentProgress}
				// setCurrentProgress={setCurrentProgress}
			/>

			{displayDisclaimer ? <Disclaimer fetchedData={fetchedData} /> : null}
			{displayGTK ? <GoodToKnow fetchedData={fetchedData} /> : null}
		</div>
	);
}

export default App;
