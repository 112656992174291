import React, { useState, useEffect, useCallback } from 'react';

export default function TextField({
	followUpIsActive,
	updateAnswer,
	currentQuestion,
	selectedValues,
	setSelectedValues,
}) {
	const [textValue, setTextValue] = useState('');
	const [header, setHeader] = useState('');

	useEffect(() => {
		const index = selectedValues.findIndex ? selectedValues.findIndex(
			(item) => item.id === selectedValues[0].id
		) : -1;
		if (index !== -1) {
			setTextValue(selectedValues[0].value);
		}

		const followUpQuestion = currentQuestion[0].alternatives.find(
			(item) => item.followup_question_uuid !== null
		);
		if (followUpQuestion != undefined) {
			setHeader(followUpQuestion.formulation);
		}
	}, [selectedValues, currentQuestion]);

	const handleChange = (e) => {
		const id = currentQuestion[0].uuid;
		// Prevents new row(keypress: 'enter')
		if (e.target.value == '\n' || e.target.value.toString().includes('\n')) {
			let noNewLine = e.target.value.substring(0, e.target.value.indexOf('\n'));
			noNewLine += e.target.value.substring(e.target.value.indexOf('\n')+1);
			let FollowUpValue = {
				id: selectedValues[0].id,
				OptionValue: noNewLine,
				extraQuestion: selectedValues[0].extraQuestion
			};
			setTextValue(noNewLine);
			setSelectedValues(FollowUpValue);
			updateAnswer(id, [FollowUpValue]);
			return;
		}
		const value = e.target.value;
		let FollowUpValue = {
			id: selectedValues[0].id,
			OptionValue: value,
			extraQuestion: selectedValues[0].extraQuestion
		};
		setTextValue(value);
		setSelectedValues([FollowUpValue]);
		updateAnswer(id, [FollowUpValue]);
	};
	return (
		<div
			className={`textarea-wrapper ${
				followUpIsActive ? 'follow-up-active' : 'follow-up-inactive'
			}`}
		>
			<h2>{header}</h2>
			<textarea
				value={textValue}
				onChange={handleChange}
			></textarea>
		</div>
	);
}
