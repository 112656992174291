import React from 'react';
import CategoryStatus from '../Components/HeaderComponents/CategoryStatus';

export default function Header({ currentProgress, fetchedData }) {
	const categories = fetchedData.steps ? fetchedData.steps.slice(1) : [];

	return (
		<div className='header-wrapper'>
			<div className='header-content-wrapper'>
				{categories.map((category) => (
					<CategoryStatus
						fetchedData={fetchedData}
						currentProgress={currentProgress}
						category={category}
						key={category.uuid}
					/>
				))}
			</div>
		</div>
	);
}
