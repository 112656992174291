import React, { useState, useEffect } from 'react';
import TextField from '../../Components/QuestionComponents/TextField';

export default function QuestionMultipleChoice({
	currentQuestion,
	updateAnswer,
	answer,
}) {
	const alternatives = currentQuestion[0].alternatives;
	const [selectedValues, setSelectedValues] = useState([]);
	const [followUpIsActive, setFollowUpIsActive] = useState(false);

	useEffect(() => {
		setSelectedValues([]);
		setFollowUpIsActive(false);
		const index = answer.findIndex(
			(item) => item.id === currentQuestion[0].uuid
		);
		if (index !== -1) {
			//setSelectedValues(answer[index].value);
			// check if answer contains answers with followup question
			if (answer[index].value.length > 0) {
				if (answer[index].value[0].extraQuestion === 'true') {
					setFollowUpIsActive(true);
				}
			} else {
				setFollowUpIsActive(false);
			}
			setSelectedValues(answer[index].value);
		}
	}, [answer, currentQuestion]);

	const handleCheck = (e) => {
		const id = currentQuestion[0].uuid;
		const value = e.target.value;
		let newSelectedValues = selectedValues;

		//CREATE OBJECT WITH ALL THE THE DATA
		let answerInfo = {
			id: e.target.getAttribute('data-id'),
			value: value,
			extraQuestion: e.target.getAttribute('data-tag'),
			summary: e.target.getAttribute('data-summary'),
		};

		//check if answerInfo.value is exists in selectedValues
		const index = selectedValues.findIndex((item) => item.id === answerInfo.id);
		//IF IT EXISTS, REMOVE IT, ELSE PUSH IT
		if (index !== -1) {
			newSelectedValues.splice(index, 1);
		} else {
			newSelectedValues.push(answerInfo);
		}

		// check if "follow up question is active"
		if (e.target.getAttribute('data-tag') === 'true' && e.target.checked) {
			answerInfo = {
				id: e.target.getAttribute('data-id'),
				value: '',
				extraQuestion: e.target.getAttribute('data-tag'),
			};
			setFollowUpIsActive(true);
			//this will remove all other selected values
			newSelectedValues = [answerInfo];
		} else {
			// find item where extraQuestion is true and remove it
			const index = newSelectedValues.findIndex(
				(item) => item.extraQuestion === 'true'
			);
			if (index !== -1) {
				newSelectedValues.splice(index, 1);
			}
			setFollowUpIsActive(false);
		}

		updateAnswer(id, newSelectedValues);
		setSelectedValues(newSelectedValues);
	};

	// for every item in alternatives, create a button with the text of the alternative
	const alternativesButtons = alternatives.map((alternative) => {
		let hasFollowUpQuestion = false;
		if (alternative.followup_question_uuid !== null) {
			hasFollowUpQuestion = true;
		}
		return (
			<div
				className='form-check'
				key={alternative.uuid}
			>
				<label>
					<input
						data-tag={hasFollowUpQuestion}
						data-id={alternative.uuid}
						data-summary={alternative.summary_formulation}
						type='checkbox'
						value={alternative.formulation}
						//if alternative.value exists in answere, then it is checked
						checked={
							selectedValues.some &&
							selectedValues.some((item) => item.id === alternative.uuid)
						}
						onChange={handleCheck}
						className='form-check-input'
					/>
					<span>{alternative.formulation}</span>
				</label>
			</div>
		);
	});
	return (
		<div className='Question-multiple-choice-wrapper'>
			<div className='question-header-wrapper'>
				<h1>{currentQuestion[0].formulation}</h1>
				<p>{currentQuestion[0].explanation}</p>
			</div>

			<div className='input-wrapper'>{alternativesButtons}</div>
			<TextField
				followUpIsActive={followUpIsActive}
				answer={answer}
				updateAnswer={updateAnswer}
				currentQuestion={currentQuestion}
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
			/>
		</div>
	);
}
