import React, { useEffect } from 'react';
import { track } from './../_helpers/postmessage';

export default function StartPage({
	fetchedData,
	currentProgress,
	setCurrentProgress,
	setDisplayDisclaimer,
}) {
	useEffect(() => {
		setDisplayDisclaimer(true);
	}, []);
	//if fetchedData is not undefined, return array steps where step_type == page
	const pageSteps = fetchedData.steps.filter(
		(step) => step.step_type === 'startpage'
	);
	function handleClick() {
		track('event', 'questionnaire_start');
		setCurrentProgress((existingValues) => ({
			...existingValues,
			currentStep: fetchedData.steps && fetchedData.steps[1].system_name,
			currentCategory: fetchedData.steps && fetchedData.steps[1].uuid,
		}));
		setDisplayDisclaimer(false);
	}
	return (
		<div className='Start-page-wrapper'>
			<div className='background'></div>
			<div className='Start-page-content-wrapper'>
				<div className='text-wrapper'>
					<div className='text-content'>
						<h1>{fetchedData.steps && fetchedData.steps[0].heading}</h1>
						<p
							dangerouslySetInnerHTML={{
								__html:
									fetchedData.steps && fetchedData.steps[0].first_paragraph,
							}}
						></p>
						<p
							dangerouslySetInnerHTML={{
								__html:
									fetchedData.steps && fetchedData.steps[0].second_paragraph,
							}}
						></p>
					</div>
					<button
						className='navigation-button forward-button'
						onClick={() => handleClick()}
					>
						{fetchedData.steps && fetchedData.steps[0].button_text}
					</button>
				</div>
				<div className='image-wrapper'>
					<img
						className='desktop-image'
						src={
							fetchedData.steps && fetchedData.steps[0]
								? fetchedData.steps[0].intro_image
								: ''
						}
						alt='placeholder'
					></img>
					<img
						className='mobile-image'
						src={
							fetchedData.steps && fetchedData.steps[0]
								? fetchedData.steps[0].intro_image_mobile
								: ''
						}
						alt='placeholder'
					></img>
				</div>
			</div>
			<div className='disclaimer-wrapper'>
				<img
					src='Icons/exclamationmark.png'
					alt='placeholder'
				></img>
				<span>{fetchedData.steps && fetchedData.steps[0].disclaimer_text}</span>
			</div>
		</div>
	);
}
